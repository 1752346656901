<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="900px">
      <template v-slot:activator="{ on }">
        <v-btn color="#90CAF9" text v-on="on">
          <slot>
            публичным договором
          </slot>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span style="word-break: normal" class="headline">Договор на оказание услуг</span>
        </v-card-title>
        <v-card-text class="text-justify"
          >1. ПРЕДМЕТ ДОГОВОРА <br />
          1.1. Исполнитель обязуется предоставить Клиенту услуги, указанные на веб сайте в
          определённом тематическом отделе (здесь и далее «Услуги»), а также дополнительные услуги,
          если таковые предусмотрены, связанные с указанными Услугами, в рамках настоящего
          Договора.<br />
          1.2. Исключительные права на Услуги принадлежат Исполнителю.<br />
          <br />
          2. ОБЩИЕ ПОЛОЖЕНИЯ<br />
          3.1. Настоящие условия являются публичным предложением Исполнителя в адрес физических и
          юридических лиц и содержат условия Договора на оказание Услуг в пределах имеющихся в
          наличии мест (здесь и далее «Условия»).
          <br />3.2. Полным и безоговорочным принятием настоящих Условий являются любые действия по
          выполнению указанных в Условиях положений, в том числе оплата, регистрация в качестве
          Клиента на Сайте Услуг (здесь и далее «Сайт»), а также иные действия, подтверждающие
          намерения лица воспользоваться Услугами. <br />3.3. С момента подтверждения Условий Клиент
          считается ознакомившимся и согласившимся с настоящими положениями и считается вступившим с
          Исполнителем в договорные отношения в соответствии с настоящими Условиями.
          <br />
          3.4. Обязанности Исполнителя ограничиваются условиями Услуг, в частности, в обязанности
          Исполнителя не входит оказание услуг Клиенту по предоставлению доступа в сеть Интернет.
          При отсутствии такового, доступ к сети интернет Клиент обеспечивает за свой счет
          самостоятельно. Также Исполнитель не предоставляет возможности приема-передачи электронных
          сообщений Клиентом. <br />3.6. Исполнитель вправе изменить или дополнить настоящие Условия
          в любой момент как с уведомлением Клиента, так и без него. Действующая редакция всегда
          находится на сайте Исполнителя на данном Сайте. Клиент берёт на себя ответственность о
          проверке применимых Услуг на момент их оказания.
          <br />
          <br />
          4. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ <br />
          4.1. Стоимость Услуг на данном Сайте в определённом тематическом отделе о конкретоной
          Услуге. <br />
          4.2. Исполнитель вправе в любое время в одностороннем порядке изменять стоимость. <br />
          4.3. Услуги оплачиваются Клиентом на условиях предоплаты, при этом способ осуществления
          предоплаты Услуг определяется Клиентом самостоятельно из числа вариантов, предложенных
          Исполнителем. <br />
          4.4. Датой оказания Услуг Исполнителем является дата проведения конкретного мероприятия,
          вебинара или иной Услуги (здесь и далее «Мероприятие»).
          <br />
          <br />
          5. ПОРЯДОК ОКАЗАНИЯ УСЛУГ <br />
          5.1. Исполнитель предоставляет Клиенту на срок действия Договора неэксклюзивное, не
          подлежащее передаче или отчуждению в любой форме, имеющее ограниченный срок действия право
          доступа к Услугам. <br />
          5.2. Расходы, в том числе банковская комиссия, по перечислению Клиентом денежных средств
          по данному Договору возлагаются на Клиента.
          <br />
          <br />
          6. ПРАВИЛА ИСПОЛЬЗОВАНИЯ САЙТА <br />
          6.1. Регистрация на Сайте осуществляется Клиентом самостоятельно. <br />
          6.2. Клиент несет полную ответственность за действия и/или бездействие, приведшие к
          разглашению, потере, краже и т.п. его учетных данных и иной информации,
          индивидуализирующей Клиента, а также за любые действия и/или бездействие третьих лиц,
          использующих учетные данные Клиента. Исполнитель не несет ответственности за вышеуказанные
          действия Клиента и/или третьих лиц, использующих его учетные данные.
          <br />
          6.3. Клиент обязуется соблюдать авторские права на предоставляемые Исполнителем и и/или
          третьими лицами программное обеспечение и документацию.
          <br />
          <br />
          7. ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ <br />
          7.1. Исполнитель обязуется: <br />
          7.1.1. Обеспечивать Услуги в соответствии с настоящими Условиями. <br />
          7.1.2. Прилагать усилия для обеспечения сохранности данных Клиента. <br />
          7.1.3. Вести учет оплаты Клиентом Услуг. <br />
          7.2. Исполнитель имеет право: <br />
          7.2.1. Отменить проведение Мероприятия в случае недостаточного количества участников. В
          таком случае, предоплата будет возвращена в полном объёме. <br />
          7.2.2 Отменить или прервать проведение Мероприятия, если это обусловлено обстоятельствами,
          не зависящими от Исполнителя, в том числе при аварийной ситуации. В таком случае,
          предоплата не будет возвращена. <br />
          7.2.3 Исполнитель не несет какой-либо ответственности перед Клиентом и не возмещает
          Клиенту какие-либо убытки и/или потерянную прибыль, а также возникшие или могущие
          возникнуть у Клиента всвязи с задержками или невозможностью полноценного использования
          Услуг Исполнителя, возникших по вышеперечисленным причинам. <br />
          7.2.4. Вносить изменения и дополнения в настоящий Договор путем публикации этих изменений
          и дополнений на Cайте.
          <br />
          <br />
          8. ОТВЕТСТВЕННОСТЬ СТОРОН <br />8.1. Клиент несет ответственность за любые свои действия
          и/или бездействие в отношении исполнения данного Договора и пользования Услугами, как
          умышленные, так и неумышленные, которые повлекли и/или могут повлечь нарушение любого
          применимого законодательства, а также за любой ущерб, причиненный вышеуказанными
          действиями и/или бездействием Исполнителю или третьим лицам. Исполнитель не несет
          ответственности за такие действия и/или бездействие Клиента. <br />
          8.2. Клиент возмещает Исполнителю убытки за вышеперечисленные действия и/или бездействия.
          <br />
          8.3. Исполнитель несет ответственность перед Клиентом в пределах оплаченных Услуг, за
          исключение случаев, когда законодательством указано иное. Исполнитель не несет перед
          Клиентом финансовой ответственности и не возвращает Клиенту уплаченные по настоящему
          Договору денежные средства, в случае если Услуги не были оказаны по вине Клиента, ровно
          как и по причине нарушения настоящих Условий. <br />
          8.4. В случае если надлежащее исполнение Сторонами настоящих Условий невозможно в силу
          объективных причин, которые Стороны не могли ни предвидеть, ни предотвратить (стихийные
          бедствия, изменения текущего применимого законодательства, действия органов
          государственной власти и управления, военные действия всех видов и т. д.), ни одна из
          Сторон не вправе требовать от другой Стороны возмещения причиненных ненадлежащим
          исполнением или неисполнением настоящего Договора убытков (включая упущенную выгоду).
          <br />
          <br />
          9. РАСТОРЖЕНИЕ ДОГОВОРА <br />
          9.1. Договор может быть расторгнут: <br />
          9.1.1. Исполнителем в любое время; <br />
          9.1.3. Исполнителем в любое время без уведомления Клиента, в случае нарушения Клиентом
          условий Договора, в таком случае предоплата не возвращается; <br />
          9.1.4. По соглашению Сторон в любое время;

          <br />
          10. ПРОЧИЕ УСЛОВИЯ <br />
          10.1. Исполнитель вправе при необходимости привлекать для оказания Услуг третьих лиц,
          имеющих соответствующую подготовку и квалификацию. <br />
          10.2. Наименование и нумерация статей настоящего Договора приведены для удобства прочтения
          и не имеют значения при толковании настоящих Условий. <br />
          10.3. В случае если какой-либо пункт данных Условий окажется не подлежащим буквальному
          исполнению, он толкуется в соответствии с действующим законодательством Италии с учетом
          первоначальных интересов Сторон, при этом оставшаяся часть Условий продолжает действовать
          в полной мере. <br />
          10.4. В случае если действие Клиента, явилось основанием для предъявления к Исполнителю
          претензий, исков третьих лиц и/или предписаний по уплате штрафных санкций со стороны
          государственных органов в связи с нарушением прав третьих лиц и/или законодательства,
          Клиент обязуется незамедлительно по требованию Исполнителя предоставить ему всю
          запрашиваемую информацию, касающуюся размещения и содержания материалов, используемых в
          Услугах, содействовать Исполнителю в урегулировании таких претензий и исков, а также
          возместить все убытки, причиненные Исполнителю вследствие предъявления ему таких
          претензий, исков, предписаний. <br />
          10.5. Данный Договор является исчерпывающим и единственным между Сторонами в отношении
          Услуг и заменяет собой все предыдущие обсуждения, переговоры, условия и договоры в их
          отношении. Все действия и обязательства Сторон в отношении данного Договора будут
          регулироваться исключительно данным Договором. <br />
          10.6. Данный Договор регулируется Итальянским законодательством и все споры в отношении
          его исполнения и применения будут решаться исключительно судами г. Милана,
          Италия.</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn color="#FB8C00" text @click="dialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'DialogOfferAgreement',
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style scoped></style>
